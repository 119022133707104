import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'

const Sobre = () => (
    <Layout>
        <div className="container">
            <article className="content" style={{ textAlign: `center` }}>
                <h1 className="content-title">Página Não Encontrada</h1>
                <section className="content-body">
                    O conteúdo que você está tentando acessar não existe... <Link to="/">Voltar para o Início</Link>
                </section>
            </article>
        </div>
    </Layout>
)

export default Sobre
